import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-save-build-modal',
  templateUrl: './save-build-modal.component.html',
  styleUrls: ['./save-build-modal.component.scss']
})
export class SaveBuildModalComponent implements OnInit {
  name: string = '';
  isPublic: boolean = true;

  constructor(    
    public dialogRef: MatDialogRef<SaveBuildModalComponent>) { }

  ngOnInit() {
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  okayClick(): void {
    console.log(this.name);
    if (this.name) {
      this.dialogRef.close({name : this.name, isPublic: this.isPublic});
    } else {
      this.dialogRef.close(null);
    }
  }

}
