import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EquipmentProvider } from '../../providers/equipment/equipment';
import { CellsProvider } from '../../providers/cells/cells';
import { PerksProvider } from '../../providers/perks/perks';
import { LoadoutsProvider } from '../../providers/loadouts/loadouts';
import { UsersProvider } from '../../providers/users/users';
import { faShieldAlt, faCertificate, faBolt, faFire, faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material';
import { EquipmentModalComponent } from '../equipment-modal/equipment-modal.component';
import { CellModalComponent } from '../cell-modal/cell-modal.component';
import { SaveBuildModalComponent } from '../save-build-modal/save-build-modal.component';

import Hashids from 'hashids';

enum Region {
  Weapon = 'WEAPON',
  Head = 'HEAD',
  Torso = 'TORSO',
  Arms = 'ARMS',
  Legs = 'LEGS',
  Lantern = 'LANTERN',
  Stats = 'STATS'
}

@Component({
  selector: 'app-builds',
  templateUrl: './builds.component.html',
  styleUrls: ['./builds.component.scss']
})
export class BuildsComponent implements OnInit {
  perkList: any;
  loadout: any;
  loadoutString: string;
  hideAlert: boolean = false;
  build: any;
  buildLink: string = '';
  liked: boolean = false;
  myBuilds: any = [];

  faShieldAlt = faShieldAlt;
  faCertificate = faCertificate;
  faBolt = faBolt;
  faFire = faFire;
  faSnowflake = faSnowflake;

  hashIds = new Hashids('myslayer');

  loading: boolean = true;

  constructor(
    public loadoutsService: LoadoutsProvider,
    public equipmentService: EquipmentProvider,
    public cellsService: CellsProvider,
    public perksService: PerksProvider,
    public route: ActivatedRoute,
    public usersService: UsersProvider,
    public dialog: MatDialog) {
    }

    ngOnInit() {
      this.loading = true;
      let self = this;
      this.loadoutString = null;
      
      this.build = {
        core : {
          totalLikes : 0
        }
      };

      this.loadout = this.loadoutsService.getEmptyLoadout();

      if (this.route.snapshot.paramMap.get('id')) {
        this.loadoutString = this.route.snapshot.paramMap.get('id');
        this.loadoutsService.getLoadoutFromString(this.loadoutString).then((build: any) => {
          self.build = build;
          self.loadout = build.loadout;
          if (this.usersService.loggedIn()) {
            this.onUserLogin();
          }
          this.updateBuildLink();
          self.loading = false;
        }).catch((error) => {
          self.loading = false;
          // console.log(error);
        });
      } else {
        self.loading = false;
      }
      this.perksService.getPerks().then((data) => {
        this.perkList = data;
      });    
    }

    onUserLogin() {
      let self = this;
      let user = <any>this.usersService.getUser();
      if (self.build && self.build.core && self.build.core.likes) {
        self.build.core.likes.forEach((userId) => {
          if (userId === user.id) {
            self.liked = true;
          }
        });
      }
      this.updateMyBuilds();
    }

    updateMyBuilds() {
      this.usersService.myBuilds().then((builds) => {
        this.myBuilds = builds;
      });
    }

    like() {
      if (!this.usersService.loggedIn()) return;
      let self = this;
      this.loadoutsService.likeBuild(this.build._id, (<any>this.usersService.getUser()).token)
      .then(() => {
        self.liked = true;
        self.build.core.totalLikes++;
      });
    }

    unlike() {
      if (!this.usersService.loggedIn()) return;
      let self = this;
      this.loadoutsService.unlikeBuild(this.build._id, (<any>this.usersService.getUser()).token)
      .then(() => {
        self.liked = false;
        self.build.core.totalLikes--;
      });
    }

    loadLoadout(loadout) {
      let loadoutArr = this.hashIds.decode(loadout);
  
      let emptyCell = 999;
      let emptyEquip = 9999;
  
      for(let i = 0; i < loadoutArr.length; i++) {
        let equipId = loadoutArr[i];
        if(equipId === emptyEquip) {
          continue;
        }
        let equipLevel = loadoutArr[++i];
        let numCells = loadoutArr[++i];
        let cellInfo = [];
        for(let j = 0; j < numCells; j++) {
          let cellId = loadoutArr[++i];
          if (cellId !== emptyCell) {
            cellInfo.push({
              id: cellId,
              power : loadoutArr[++i]
            });  
          } else {
            cellInfo.push({
              id: null,
              power: 1
            });
          }
        }
  
        this.equipmentService.getEquipmentById(equipId).then((equipment: any) => {
          equipment.level = equipLevel;
  
          equipment.cellSlots.forEach((cellSlot, idx) => {
            if (!cellInfo[idx].id) {
              cellSlot.cell = null;
            } else {
              this.perksService.getPerksById(cellInfo[idx].id).then((perk: any) => {
                cellSlot.cell = {};
                cellSlot.cell.perk = perk;
                cellSlot.cell.power = cellInfo[idx].power;
              });
            }
          });
  
          switch(equipment.slot) {
            case 'WEAPON':
              this.loadout.weapon = equipment;
              break;
            case 'LANTERN':
              this.loadout.lantern = equipment;
              break;
            case 'HEAD':
              this.loadout.head = equipment;
              break;
            case 'TORSO':
              this.loadout.torso = equipment;
              break;
            case 'ARMS':
              this.loadout.arms = equipment;
              break;
            case 'LEGS':
              this.loadout.legs = equipment;
              break;
          }
        });
      }
    }

    getResistances(equipment: any = null) {
      let resistances = {
        'FIRE' : 0,
        'SHOCK' : 0,
        'FROST' : 0,
        'UMBRAL' : 0,
        'RADIANT' : 0
      };
  
      if (this.loadout !== null) {
        if (this.loadout.head && this.loadout.head.elements && (equipment === null || equipment == this.loadout.head)) {
          this.loadout.head.elements.forEach(element => {
            resistances[element.element] += element.value;
          });
        }
        if (this.loadout.torso && this.loadout.torso.elements && (equipment === null || equipment == this.loadout.torso)) {
          this.loadout.torso.elements.forEach(element => {
            resistances[element.element] += element.value;
          });
        }
        if (this.loadout.arms && this.loadout.arms.elements && (equipment === null || equipment == this.loadout.arms)) {
          this.loadout.arms.elements.forEach(element => {
            resistances[element.element] += element.value;
          });
        }
        if (this.loadout.legs && this.loadout.legs.elements && (equipment === null || equipment == this.loadout.legs)) {
          this.loadout.legs.elements.forEach(element => {
            resistances[element.element] += element.value;
          });
        }
        if (this.loadout.weapon && this.loadout.weapon.elements && equipment == this.loadout.weapon) {
          this.loadout.weapon.elements.forEach(element => {
            resistances[element.element] += element.value;
          });
        }
      }
  
      let resArr: any = [];
      if (resistances.FIRE !== 0) {
        resArr.push({element: 'FIRE', value: resistances.FIRE});
      }
      if (resistances.SHOCK !== 0) {
        resArr.push({element: 'SHOCK', value: resistances.SHOCK});
      }
      if (resistances.FROST !== 0) {
        resArr.push({element: 'FROST', value: resistances.FROST});
      }
      if (resistances.UMBRAL !== 0) {
        resArr.push({element: 'UMBRAL', value: resistances.UMBRAL});
      }
      if (resistances.RADIANT !== 0) {
        resArr.push({element: 'RADIANT', value: resistances.RADIANT});
      }
      
      resArr.sort(function(a,b) {return a.value < b.value;});
      
      return resArr;
    }

    getName(region: Region) {
      if (this.loadout === null) {
        return 'Empty';
      }
  
      var name: string = "Empty";
  
      switch(region) {
        case Region.Weapon:
          if (this.loadout.weapon !== null) {
            name = this.loadout.weapon.name;
          }
          break;
        case Region.Lantern:
          if (this.loadout.lantern !== null) {
            name = this.loadout.lantern.name;
          }
          break;
        case Region.Head:
          if (this.loadout.head !== null) {
            name = this.loadout.head.name;
          }
          break;
        case Region.Torso:
          if (this.loadout.torso !== null) {
            name = this.loadout.torso.name;
          }
          break;
        case Region.Arms:
          if (this.loadout.arms !== null) {
            name = this.loadout.arms.name;
          }
          break;
        case Region.Legs:
          if (this.loadout.legs !== null) {
            name = this.loadout.legs.name;
          }
          break;
        default:
          break;
      }
  
      return name;
    }

    getEquipmentPower(equipment: any) {
      if (equipment === null) {
        return 0;
      }
      let level = equipment.level ? equipment.level : 0;
      return equipment.power + level * equipment.powerPerLevel;
    }
  
    getEquipmentPerks(equipment: any, calculateCells: boolean = true) {
      let perks: any = [];
      if (equipment && equipment.perks) {
        equipment.perks.forEach(perk => {
          if ((equipment.level ? equipment.level : 0) >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
  
      if (calculateCells) {
        if (equipment && equipment.cellSlots) {
          equipment.cellSlots.forEach(cell => {
            if (cell.cell) {
              let foundPerk = false;
              perks.forEach(perkItem => {
              if(perkItem.perk.perk === cell.cell.perk._id) {
                  foundPerk = true;
                  perkItem.level += cell.cell.power;
                }
              });          
              if (!foundPerk) {
                perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
              }
            }
          });
        }
      }
  
      perks.sort(function(a,b) {return a.level < b.level;});
  
      let perksResult: Array<string> = [];
  
      perks.forEach(perk => {
        let name: string;
  
        this.perkList.forEach(populatedPerk => {
          if (populatedPerk._id === perk.perk.perk) {
            name = populatedPerk.name;
          }
        })
        perksResult.push('+' + perk.level + ' ' + name);
      });
  
      return perksResult;
    }

    getEquipmentLevelType(equipment: any) {
      let display: string = equipment.level ? 'Level ' + equipment.level : 'Base Level';
      display += ' ' + (equipment.subType ? equipment.subType : equipment.slot + ' Armor') ;
      return display;
    }
  
    getEquipmentByRegion(region: Region) {
      switch(region) {
        case Region.Weapon:
          return this.loadout.weapon;
        case Region.Lantern:
          return this.loadout.lantern;
        case Region.Head:
          return this.loadout.head;
        case Region.Torso:
          return this.loadout.torso;
        case Region.Arms:
          return this.loadout.arms;
        case Region.Legs:
          return this.loadout.legs;
        default:
          return null;
      }
    }

    getUniqueEffects() {
      let uniqueEffects: Array<string> = [];
      if (this.loadout.weapon && this.loadout.weapon.uniqueEffects && this.loadout.weapon.uniqueEffects.length > 0) {
        this.loadout.weapon.uniqueEffects.forEach(effect => {
          if (effect.length === 1 || this.loadout.weapon.level < 6) {
            uniqueEffects.push(effect[0]);
          } else {
            uniqueEffects.push(effect[1]);
          }
        });
      }
      if (this.loadout.head && this.loadout.head.uniqueEffects && this.loadout.head.uniqueEffects.length > 0) {
        this.loadout.head.uniqueEffects.forEach(effect => {
          if (effect.length === 1 || this.loadout.head.level < 6) {
            uniqueEffects.push(effect[0]);
          } else {
            uniqueEffects.push(effect[1]);
          }
        });
      }
      if (this.loadout.torso && this.loadout.torso.uniqueEffects && this.loadout.torso.uniqueEffects.length > 0) {
        this.loadout.torso.uniqueEffects.forEach(effect => {
          if (effect.length === 1 || this.loadout.torso.level < 6) {
            uniqueEffects.push(effect[0]);
          } else {
            uniqueEffects.push(effect[1]);
          }
        });
      }
      if (this.loadout.arms && this.loadout.arms.uniqueEffects && this.loadout.arms.uniqueEffects.length > 0) {
        this.loadout.arms.uniqueEffects.forEach(effect => {
          if (effect.length === 1 || this.loadout.arms.level < 6) {
            uniqueEffects.push(effect[0]);
          } else {
            uniqueEffects.push(effect[1]);
          }
        });
      }
      if (this.loadout.legs && this.loadout.legs.uniqueEffects && this.loadout.legs.uniqueEffects.length > 0) {
        this.loadout.legs.uniqueEffects.forEach(effect => {
          if (effect.length === 1 || this.loadout.legs.level < 6) {
            uniqueEffects.push(effect[0]);
          } else {
            uniqueEffects.push(effect[1]);
          }
        });
      }
  
      return uniqueEffects;
    }

  getTotalDefence() {
    let defence: Number = 0;

    if (this.loadout.head !== null) {
      let level = this.loadout.head.level ? this.loadout.head.level: 0;
      defence += this.loadout.head.power + level * this.loadout.head.powerPerLevel;
    }

    if (this.loadout.torso !== null) {
      let level = this.loadout.torso.level ? this.loadout.torso.level: 0;
      defence += this.loadout.torso.power + level * this.loadout.torso.powerPerLevel;
    }

    if (this.loadout.arms !== null) {
      let level = this.loadout.arms.level ? this.loadout.arms.level: 0;
      defence += this.loadout.arms.power + level * this.loadout.arms.powerPerLevel;
    }

    if (this.loadout.legs !== null) {
      let level = this.loadout.legs.level ? this.loadout.legs.level: 0;
      defence += this.loadout.legs.power + level * this.loadout.legs.powerPerLevel;
    }

    return defence;
  }

  getCells() {
    let cells: any = [];

    let equipmentSlots = [
      this.loadout.weapon,
      this.loadout.lantern,
      this.loadout.head,
      this.loadout.torso,
      this.loadout.arms,
      this.loadout.legs
    ];

    equipmentSlots.forEach((equipment) => {
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundCell = false;
            cells.forEach(cellItem => {
            if(cellItem.perk.perk === cell.cell.perk._id && parseInt(cellItem.level) === parseInt(cell.cell.power)) {
                foundCell = true;
                cellItem.count++;
              }
            });          
            if (!foundCell) {
              cells.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power, count: 1});
            }
          }
        });
      }
    });

    cells.sort(function(a,b) {return a.level < b.level;});
  
    let cellsResult: Array<string> = [];

    cells.forEach(perk => {
      let name: string;

      this.perkList.forEach(populatedPerk => {
        if (populatedPerk._id === perk.perk.perk) {
          name = populatedPerk.name;
        }
      })
      cellsResult.push('+' + perk.level + ' ' + name + (perk.count > 1 ? ' x' + perk.count : ''));
    });

    return cellsResult;
  }

  getCellPerks(equipment: any) {
    let perks: any = [];

    if (equipment && equipment.cellSlots) {
      equipment.cellSlots.forEach(cell => {
        if (cell.cell) {
            perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
        } else {
          perks.push({'perk' : null, 'level': cell.perkType});
        }
      });
    }

    //perks.sort(function(a,b) {return a.level < b.level;});
  
    let perksResult: Array<string> = [];

    perks.forEach(perk => {
      let name: string;

      if (perk.perk === null) {
        perksResult.push('Empty ' + perk.level.charAt(0).toUpperCase() + perk.level.toLowerCase().substr(1) + ' Slot');
        return;
      }

      this.perkList.forEach(populatedPerk => {
        if (populatedPerk._id === perk.perk.perk) {
          name = populatedPerk.name;
        }
      })
      perksResult.push('+' + perk.level + ' ' + name);
    });

    return perksResult;
  }

  getPerks() {
    let perks: any = [];
    if (this.loadout.lantern) {
      let equipment = this.loadout.lantern;
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += parseInt(cell.cell.power);
              }
            });
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': parseInt(cell.cell.power)});
            }
          }
        });
      }
    }

    if (this.loadout.head) {
      let equipment = this.loadout.head;
      if (this.loadout.head.perks) {
        this.loadout.head.perks.forEach(perk => {
          if (this.loadout.head.level ? this.loadout.head.level : 0 >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += parseInt(cell.cell.power);
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': parseInt(cell.cell.power)});
            }
          }
        });
      }
    }
    
    if (this.loadout.torso) {
      let equipment =this.loadout.torso;
      if (this.loadout.torso.perks) {
        this.loadout.torso.perks.forEach(perk => {
          if (this.loadout.torso.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += parseInt(cell.cell.power);
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': parseInt(cell.cell.power)});
            }
          }
        });
      }
    }

    if (this.loadout.arms) {
      let equipment = this.loadout.arms;
      if (this.loadout.arms.perks) {
        this.loadout.arms.perks.forEach(perk => {
          if (this.loadout.arms.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += parseInt(cell.cell.power);
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': parseInt(cell.cell.power)});
            }
          }
        });
      }
    }

    if (this.loadout.legs) {
      let equipment = this.loadout.legs;
      if (this.loadout.legs.perks) {
        this.loadout.legs.perks.forEach(perk => {
          if (this.loadout.legs.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += parseInt(cell.cell.power);
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': parseInt(cell.cell.power)});
            }
          }
        });
      }
    }

    if (this.loadout.weapon) {
      let equipment = this.loadout.weapon;
      if (this.loadout.weapon.perks) {
        this.loadout.weapon.perks.forEach(perk => {
          if (this.loadout.weapon.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += parseInt(cell.cell.power);
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': parseInt(cell.cell.power)});
            }
          }
        });
      }
    }

    perks.sort(function(a,b) {return a.level < b.level;});

    let perksResult: any = [];

    perks.forEach(perk => {
      let name: string;
      let description: string;

      this.perkList.forEach(populatedPerk => {
        if (populatedPerk._id === perk.perk.perk) {
          name = populatedPerk.name;
          description = populatedPerk.effects[perk.level - 1];
        }
      });
      perksResult.push({
        'display' : '+' + perk.level + ' ' + name,
        'description' : description
      });
    })

    return perksResult;
  } 

  selectEquipment(region: Region) {
    let self = this;
    const dialogRef = this.dialog.open(EquipmentModalComponent, {
      data: {
        equipment : this.getEquipmentByRegion(region),
        region : region
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        console.log('canceled');
      } else {
        switch(region) {
          case 'WEAPON':
            self.loadout.weapon = result;
            break;
          case 'HEAD':
            self.loadout.head = result;
            break;
          case 'TORSO':
            self.loadout.torso = result;
            break;
          case 'LEGS':
            self.loadout.legs = result;
            break;
          case 'ARMS':
            self.loadout.arms = result;
            break;
          case 'LANTERN':
            self.loadout.lantern = result;
            break;
        }
        self.updateBuildLink();
      }
    });
  }

  selectCell(region: Region, cellIndex: number) {
    let self = this;
    const dialogRef = this.dialog.open(CellModalComponent, {
      data: {
        perkType: this.getEquipmentByRegion(region).cellSlots[cellIndex].perkType, 
        currentCell: this.getEquipmentByRegion(region).cellSlots[cellIndex].cell    // { perk, power }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        console.log('canceled');
      } else {
        switch(region) {
          case 'WEAPON':
            self.loadout.weapon.cellSlots[cellIndex].cell = result;
            break;
          case 'HEAD':
            self.loadout.head.cellSlots[cellIndex].cell = result;
            break;
          case 'TORSO':
            self.loadout.torso.cellSlots[cellIndex].cell = result;
            break;
          case 'LEGS':
            self.loadout.legs.cellSlots[cellIndex].cell = result;
            break;
          case 'ARMS':
            self.loadout.arms.cellSlots[cellIndex].cell = result;
            break;
          case 'LANTERN':
            self.loadout.lantern.cellSlots[cellIndex].cell = result;
            break;
        }
        self.updateBuildLink();
      }
    });
  }
  
  getEquipmentList() {
    let equipList = [      
      this.loadout.weapon ? this.loadout.weapon : { slot : 'Weapon' },
      this.loadout.lantern ? this.loadout.lantern : { slot : 'Lantern' },
      this.loadout.head ? this.loadout.head : { slot : 'Head' },
      this.loadout.arms ? this.loadout.arms : { slot : 'Arms' },
      this.loadout.torso ? this.loadout.torso : { slot : 'Torso' },
      this.loadout.legs ? this.loadout.legs : { slot : 'Legs' }
    ];

    return equipList;
  }

  copyLink(idToCopy) {
    const node = document.getElementById(idToCopy);
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    /*
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.buildLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    */
  }

  updateBuildLink() {
    let self = this;
    this.loadoutString = this.generateLoadoutString();
    console.log(this.loadoutString, this.loadout);
    this.loadoutsService.getLoadoutFromString(this.loadoutString).then((build: any) => {
      console.log(build);
      self.build = build;
      self.loadout = build.loadout;
      self.buildLink = 'https://myslayer.com/builds/' + build._id;
      if (self.usersService.loggedIn()) {
        self.onUserLogin();
        this.updateMyBuilds();
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  generateLoadoutString() {
    let loadout = {
      weapon : {
        name : "",
        level : "",
        cells : null
      },
      lantern : {
        name : "",
        level : "",
        cells : null
      },
      head : {
        name : "",
        level : "",
        cells : null
      },
      torso : {
        name : "",
        level : "",
        cells : null
      },
      arms : {
        name : "",
        level : "",
        cells : null
      },
      legs : {
        name : "",
        level : "",
        cells : null
      },
    }

    let shortArr = [];
    let emptyCell = 999;
    let emptyEquip = 9999;

    if (this.loadout.weapon) {
      shortArr.push(this.loadout.weapon.id);
      shortArr.push(this.loadout.weapon.level);
      loadout.weapon.name = this.loadout.weapon.name;
      loadout.weapon.level = this.loadout.weapon.level;
      if (this.loadout.weapon.cellSlots) {
        loadout.weapon.cells = [];
        shortArr.push(this.loadout.weapon.cellSlots.length);
        this.loadout.weapon.cellSlots.forEach(cell => {
          if (!cell.cell) {
            shortArr.push(emptyCell);
            return;
          }
          shortArr.push(cell.cell.perk.id);
          shortArr.push(cell.cell.power);
          loadout.weapon.cells.push({
            name: cell.cell.perk.name,
            power: cell.cell.power
          })
        });
      }
    } else {
      shortArr.push(emptyEquip);
    }

    if (this.loadout.lantern) {
      shortArr.push(this.loadout.lantern.id);
      shortArr.push(this.loadout.lantern.level);
      loadout.lantern.name = this.loadout.lantern.name;
      loadout.lantern.level = this.loadout.lantern.level;
      if (this.loadout.lantern.cellSlots) {
        loadout.lantern.cells = [];
        shortArr.push(this.loadout.lantern.cellSlots.length);
        this.loadout.lantern.cellSlots.forEach(cell => {
          if (!cell.cell) {
            shortArr.push(emptyCell);
            return;
          }
          shortArr.push(cell.cell.perk.id);
          shortArr.push(cell.cell.power);
          loadout.lantern.cells.push({
            name: cell.cell.perk.name,
            power: cell.cell.power
          })
        });
      }
    } else {
      shortArr.push(emptyEquip);
    }

    if (this.loadout.head) {
      shortArr.push(this.loadout.head.id);
      shortArr.push(this.loadout.head.level);
      loadout.head.name = this.loadout.head.name;
      loadout.head.level = this.loadout.head.level;
      if (this.loadout.head.cellSlots) {
        loadout.head.cells = [];
        shortArr.push(this.loadout.head.cellSlots.length);
        this.loadout.head.cellSlots.forEach(cell => {
          if (!cell.cell) {
            shortArr.push(emptyCell);
            return;
          }
          shortArr.push(cell.cell.perk.id);
          shortArr.push(cell.cell.power);
          loadout.head.cells.push({
            name: cell.cell.perk.name,
            power: cell.cell.power
          })
        });
      }
    } else {
      shortArr.push(emptyEquip);
    }

    if (this.loadout.torso) {
      shortArr.push(this.loadout.torso.id);
      shortArr.push(this.loadout.torso.level);
      loadout.torso.name = this.loadout.torso.name;
      loadout.torso.level = this.loadout.torso.level;
      if (this.loadout.torso.cellSlots) {
        loadout.torso.cells = [];
        shortArr.push(this.loadout.torso.cellSlots.length);
        this.loadout.torso.cellSlots.forEach(cell => {
          if (!cell.cell) {
            shortArr.push(emptyCell);
            return;
          }
          shortArr.push(cell.cell.perk.id);
          shortArr.push(cell.cell.power);
          loadout.torso.cells.push({
            name: cell.cell.perk.name,
            power: cell.cell.power
          })
        });
      }
    } else {
      shortArr.push(emptyEquip);
    }

    if (this.loadout.arms) {
      shortArr.push(this.loadout.arms.id);
      shortArr.push(this.loadout.arms.level);
      loadout.arms.name = this.loadout.arms.name;
      loadout.arms.level = this.loadout.arms.level;
      if (this.loadout.arms.cellSlots) {
        loadout.arms.cells = [];
        shortArr.push(this.loadout.arms.cellSlots.length);
        this.loadout.arms.cellSlots.forEach(cell => {
          if (!cell.cell) {
            shortArr.push(emptyCell);
            return;
          }
          shortArr.push(cell.cell.perk.id);
          shortArr.push(cell.cell.power);
          loadout.arms.cells.push({
            name: cell.cell.perk.name,
            power: cell.cell.power
          })
        });
      }
    } else {
      shortArr.push(emptyEquip);
    }

    if (this.loadout.legs) {
      shortArr.push(this.loadout.legs.id);
      shortArr.push(this.loadout.legs.level);
      loadout.legs.name = this.loadout.legs.name;
      loadout.legs.level = this.loadout.legs.level;
      if (this.loadout.legs.cellSlots) {
        loadout.legs.cells = [];
        shortArr.push(this.loadout.legs.cellSlots.length);
        this.loadout.legs.cellSlots.forEach(cell => {
          if (!cell.cell) {
            shortArr.push(emptyCell);
            return;
          }
          shortArr.push(cell.cell.perk.id);
          shortArr.push(cell.cell.power);
          loadout.legs.cells.push({
            name: cell.cell.perk.name,
            power: cell.cell.power
          })
        });
      }
    } else {
      shortArr.push(emptyEquip);
    }

    return this.hashIds.encode(shortArr);
  }
  
  hasBuildSaved() {
    let user = <any>(this.usersService.getUser());
    if (!user) {
      return false;
    }

    let loadoutSaved = false;
    this.myBuilds.forEach((build) => {
      if (build.build.id === this.build._id) {
        loadoutSaved = true;
      }
    });
    return loadoutSaved;
  }

  saveBuild() {
    if (this.hasBuildSaved()) {
      this.usersService.unsaveBuild(this.build._id).then(() => {
        this.updateMyBuilds();
      });
    } else {
      let self = this;
      const dialogRef = this.dialog.open(SaveBuildModalComponent);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.usersService.saveBuild(this.loadoutString, result.name, result.isPublic).then(() => {
            this.updateMyBuilds();
          });
        }
      });
    }
  }
}
