import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsersProvider {

  host = 'https://my-slayer-server.herokuapp.com';
// host = 'http://localhost:8080';


  user : any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  constructor(public http: HttpClient) { }

  loggedIn() {
    return this.user != null;
  }

  getUser() {
    if (this.user) {
      if ((this.user.expires * 1000) > Date.now()) {
        return this.user;
      } else {
        this.logout();
      }
    } else {
      return null;
    }
  }

  login(email: String, password: String) {
    return new Promise((resolve, reject) => {
      this.http.post(this.host + '/auth/login', {email: email, password: password})
        .subscribe((data: any)  => {
          console.log(data);
          this.user = {
            id : data.user.id, 
            username : data.user.username,
            email : data.user.email,
            verified : data.user.verified,
            builds : data.user.builds,
            expires : data.user.expires,
            token : data.token
          };

          console.log(this.user);
          localStorage.setItem('user', JSON.stringify(this.user));
          resolve(this.user);
        }, (error) => {
          console.log('fail to login');
          reject('Login Error');
        });
    });
  }

  register(email: String, username: String, password: String) {
    return new Promise((resolve, reject) => {
      this.http.post(this.host + '/auth/signup', {email: email, username: username, password: password})
        .subscribe((data: any)  => {
          this.user = {
            id : data.user.id,
            username : data.user.username,
            email : data.user.email,
            verified : data.user.verified,
            builds : data.user.builds,
            expires : data.user.expires,
            token : data.token
          };
          localStorage.setItem('user', JSON.stringify(this.user));
          resolve(this.user);
        }, (error) => {
          console.log('fail to register');
          reject(error.error ? error.error : 'Registration error');
        });
    });
  }

  logout() {
    this.user = null;
    localStorage.clear();
  }

  saveBuild(buildString: string, buildName: string, isPublic: boolean) {
    if (!this.user) {
      return Promise.reject('Not logged in.');
    }

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.user.token);

    return new Promise((resolve, reject) => {
      this.http.post(this.host + '/api/users/builds', {buildName: buildName, buildString: buildString, buildOrder: 100, public: isPublic}, {headers: headers})
        .subscribe(()  => {
          return resolve();
        }, (error) => {
          console.log('failed to save build');
          reject(error.error ? error.error : 'Error saving build.');
        });
    });
  }

  unsaveBuild(buildId: string) {
    if (!this.user) {
      return Promise.reject('Not logged in.');
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.user.token);

    return new Promise((resolve, reject) => {
      this.http.delete(this.host + '/api/users/builds/' + buildId, {headers: headers})
        .subscribe(()  => {
          return resolve();
        }, (error) => {
          console.log('failed to delete build');
          reject(error.error ? error.error : 'Error deleting build.');
        });
    });
  }

  myBuilds() {
    if (!this.user) {
      return Promise.reject('Not logged in.');
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.user.token);

    return new Promise((resolve, reject) => {
      this.http.get(this.host + '/api/users/builds/', {headers: headers})
        .subscribe((builds: any)  => {
          return resolve(builds);
        }, (error) => {
          console.log('failed to get builds');
          reject(error.error ? error.error : 'Error getting builds.');
        });
    });
  }

  getBuilds(username: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.host + '/api/users/' + username + '/builds')
        .subscribe((builds: any)  => {
          return resolve(builds);
        }, (error) => {
          console.log('failed to get user builds');
          reject(error.error ? error.error : 'Error getting user builds.');
        });
    });
  }

  getProfile(username: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.host + '/api/users/' + username)
        .subscribe((profile: any)  => {
          return resolve(profile);
        }, (error) => {
          console.log('failed to get profile');
          reject(error.error ? error.error : 'Error getting profile.');
        });
    });
  }

  myProfile() {
    if (!this.user) {
      return Promise.reject('Not logged in.');
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.user.token);

    return new Promise((resolve, reject) => {
      this.http.get(this.host + '/api/profile', {headers: headers})
        .subscribe((profile: any)  => {
          return resolve(profile);
        }, (error) => {
          console.log('failed to get my profile');
          reject(error.error ? error.error : 'Error getting my profile.');
        });
    });
  }

  verify(authId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.host + '/auth/verify/' + authId)
        .subscribe((msg: any)  => {
          return resolve(msg);
        }, (error) => {
          console.log('failed to auth');
          reject(error.error ? error.error : 'Error Authorizing.');
        });
    });
  }
}
