import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsersProvider } from '../users/users';
import { resolve, reject } from 'q';

/*
  Generated class for the CellsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GuidesProvider {

  host = 'https://my-slayer-server.herokuapp.com';
//  host = 'http://localhost:8080';

  constructor(public http: HttpClient, public usersService: UsersProvider) {
  }

  search(options: any) {
    return new Promise(resolve => {
      this.http.post(this.host + '/api/guides/search', { options: options})
        .subscribe(data  => {
          console.log(data);
          resolve(data);
        });
    });
  }

  getGuide(slug: string) {
    return new Promise(resolve => {
      this.http.get(this.host + '/api/guides/' + slug)
        .subscribe(data  => {
          console.log(data);
          resolve(data);
        });
    });
  }

  createGuide(guide) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.usersService.getUser().token);

    return new Promise((resolve, reject) => {
    this.http.post(this.host + '/api/guides', guide, {headers: headers})
      .subscribe(data => {
        console.log(data);
        resolve(data);
      }, (err) => { reject(err)});
    });
  }

  editGuide(id, guide) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.usersService.getUser().token);

    return new Promise((resolve, reject) => {
    this.http.post(this.host + '/api/guides/'+id, guide, {headers: headers})
      .subscribe(data => {
        console.log(data);
        resolve(data);
      }, (err) => {
        reject(err);
      });
    });
  }

  unlike(guideId: string) {     // /api/builds/unlike/:buildId
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.usersService.getUser().token);

      this.http.get(this.host + '/api/guides/unlike/' + guideId, { headers : headers })
      .subscribe((data: any) => {
        resolve();
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }

  like(guideId: string) {    // /api/builds/like/:buildId
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.usersService.getUser().token);

      console.log(headers);

      this.http.get(this.host + '/api/guides/like/' + guideId, { headers : headers })
      .subscribe((data: any) => {
        resolve();
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }  

  userGuides(username: string) {
    return new Promise((resolve, reject) => {
      this.http.get(this.host + '/api/guides/user/' + username)
        .subscribe((guides: any)  => {
          console.log(guides);
          return resolve(guides);
        }, (error) => {
          console.log('failed to get guides');
          reject(error.error ? error.error : 'Error getting guides.');
        });
    });
  }

  makePublic(guideId: string) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.usersService.getUser().token);

      console.log(headers);

      this.http.get(this.host + '/api/guides/public/' + guideId, { headers : headers })
      .subscribe((data: any) => {
        resolve();
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }

  makePrivate(guideId: string) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.usersService.getUser().token);

      console.log(headers);

      this.http.get(this.host + '/api/guides/private/' + guideId, { headers : headers })
      .subscribe((data: any) => {
        resolve();
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }
}
