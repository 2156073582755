import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersProvider } from '../../providers/users/users';
import { GuidesProvider } from '../../providers/guides/guides';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile: any = null;
  builds: any = [];
  guides: any = [];

  constructor(public route: ActivatedRoute,
    public router: Router,
    public usersService: UsersProvider,
    public guidesService: GuidesProvider) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('username')) {
      // Check if user exists
      this.usersService.getProfile(this.route.snapshot.paramMap.get('username')).then((profile) => {
        if (!profile) {
          this.router.navigate(['/']);
          return;
        } else {
          this.profile = profile;
          console.log(this.profile);
          this.usersService.getBuilds(this.route.snapshot.paramMap.get('username')).then((builds) => {
            if (builds) {
              this.builds = builds;
              console.log(this.builds);
            }
          }).catch((error) => {
            console.log(error);
          });
          this.guidesService.userGuides(this.usersService.getUser().username).then((guides) => {
            if (guides) {
              this.guides = guides;
              console.log(guides);
            }
          }).catch((error) => {
            console.log(error);
          });
        }
      }).catch((error) => {
        this.router.navigate(['/']);
      });
    } else if (this.usersService.loggedIn()) {
      this.usersService.myProfile().then((profile) => {
        this.profile = profile;
        this.usersService.myBuilds().then((builds) => {
          if (builds) {
            this.builds = builds;
            console.log(builds);
          }
        }).catch((error) => {
          console.log(error);
        });
        this.guidesService.userGuides(this.usersService.getUser().username).then((guides) => {
          if (guides) {
            this.guides = guides;
            console.log(guides);
          }
        }).catch((error) => {
          console.log(error);
        });
        console.log(this.profile);
      }).catch((error) => {
        this.router.navigate(['/']);
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  myProfile() {
    return (!this.route.snapshot.paramMap.get('username'));
  }

  openBuild(build) {
    this.router.navigate(['/builds/' + build.build.id]);
  }

  openGuide(guide) {
    this.router.navigate(['/guides/' + guide.slug]);
  }

  logout() {
    this.usersService.logout();
    this.router.navigate(['/profile/' + this.profile.username]);
  }

  makePublic(guide) {
    this.guidesService.makePublic(guide._id).then(() => {
      guide.public = true;
    });
  }

  makePrivate(guide) {
    this.guidesService.makePrivate(guide._id).then(() => {
      guide.public = false;
    });
  }
}
