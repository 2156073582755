import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speedruns',
  templateUrl: './speedruns.component.html',
  styleUrls: ['./speedruns.component.scss']
})
export class SpeedrunsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
