import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the PerksProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PerksProvider {

  host = 'https://my-slayer-server.herokuapp.com';
//  host = 'http://localhost:8080';

  data: any;
  constructor(public http: HttpClient) {
    this.data = null;
  }

  getPerks() {
    if (this.data) {
      return Promise.resolve(this.data);
    }
    return new Promise(resolve => {
      this.http.get(this.host + '/api/perks/')
        .subscribe(data  => {
          this.data = data;
          resolve(this.data);
        });
    });
  }

  getPerksByType(perkType: string) {
    return new Promise(resolve => {
      this.getPerks()
        .then(data => {
          let res: Array<any> = [];
          data.forEach(perk => {
            if (perk.perkType === perkType) {
              res.push(perk);
            }
          })
        resolve(res);
      });
    });
  }
  
  getPerksByName(perkName: string) : any {
    return new Promise(resolve => {
      this.getPerks()
        .then(data => {
          data.forEach(perk => {
            if (perk.name === perkName) {
              return resolve(perk)
            }
          });
          resolve(null);
      });
    });
  }

  getPerksById(perkId: number) : any {
    return new Promise(resolve => {
      this.getPerks()
        .then(data => {
          data.forEach(perk => {
            if (perk.id === perkId) {
              return resolve(perk)
            }
          });
          resolve(null);
      });
    });
  }

  getPerkIcon(perk: any) {
    if (perk === null) {
      return null;
    }

    console.log(perk);

    // 1. Check for exoticName
    // 2. Check for behemoth (if null, recruit)
    // 3. Get behemoth imagePath

    /*
    let type = 'armour';
    let subType = 'head';
    let name = 'shrowd';

    if (equipment.subType) {
      type = 'weapons';
      subType = equipment.subType.toLowerCase();
    } else {
      subType = equipment.slot.toLowerCase();
    }

    if (equipment.exoticName) {
      type = 'exotics';
      subType = null;
      name = equipment.exoticName + '.png';
    } else if (equipment.slot === 'LANTERN') {
      type = 'lanterns';
      subType = null;
      name = 'lantern.png';
    } else if (equipment.behemoth) {
      name = equipment.behemoth.imagePath;
    }

    if (subType) {
      return '/assets/imgs/dauntless-images/' + type + '/' + subType + '/' + name;
    } else {
      return '/assets/imgs/dauntless-images/' + type + '/' + name;
    }
    */
  }  
}
