import { Component, OnInit, Inject, HostListener, Injectable, ViewChild } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadoutsProvider } from '../../providers/loadouts/loadouts';
import { GuidesProvider } from '../../providers/guides/guides';
import { UsersProvider } from '../../providers/users/users';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormCanDeactivate } from '../form-can-deactivate/form-can-deactivate';
import { DomSanitizer } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';

export interface DialogData {
  message: string;
}

@Component({
  selector: 'dialog-overview-example-dialog',
  template: '<div class="alert-message">{{message}}</div>',
})
export class AlertDialog {

  message: string;

  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.message = data.message;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

enum checkState {
  Valid = 1,
  Invalid,
  Checking
}

@Component({
  selector: 'app-guide-edit',
  templateUrl: './guide-edit.component.html',
  styleUrls: ['./guide-edit.component.scss']
})
export class GuideEditComponent extends FormCanDeactivate implements OnInit {

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    console.log('unloadNotification');
    if (this.hasUnsavedData()) {
        $event.returnValue =true;
    }
  }

  guideId: string;
  slug: string;
  title: string;
  build: string;
  markdown: string;
  buildCheck: checkState = checkState.Checking;  

  @ViewChild('form')
  form: NgForm;

  constructor(
    private _markdown: MarkdownService,
    public route: ActivatedRoute,
    public router: Router,
    public loadoutsService: LoadoutsProvider,
    public usersService: UsersProvider,
    public guidesService: GuidesProvider,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer) { 
      super();
  }

  ngOnInit() {
    this.guideId = "";
    this.title = "";
    this.build = "";
    this.markdown = "";
    if (this.route.snapshot.paramMap.get('slug')) {
      this.slug = this.route.snapshot.paramMap.get('slug');
      this.guidesService.getGuide(this.slug).then((guide: any) => {
        this.title = guide.title;
        this.build = guide.builds[0];
        this.markdown = guide.chapters[0].markdown;
        this.guideId = guide._id;
        this.checkBuild(this.build);
      });
    } else {
    this.markdown = `# User Markdown Here
---
    
Use markdown to write your guide.  In addition to the cheatsheet below you can also link Youtube videos just like normal links and we'll add them in for you.
    
[Youtube Link](https://youtu.be/bQF0XCJ_0Ws)
    
[Markdown cheatsheet](https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet)`;
    }

    this._markdown.renderer.link = function(href, title, text) {
      if (this.options.sanitize) {
        try {
          var prot = decodeURIComponent(unescape(href))
            .replace(/[^\w:]/g, '')
            .toLowerCase();
        } catch (e) {
          return text;
        }
        if (prot.indexOf('javascript:') === 0 || prot.indexOf('vbscript:') === 0 || prot.indexOf('data:') === 0) {
          return text;
        }
      }
      let youtubeWatchRegex = /https:\/\/.*?youtube.com\/watch\?v=([a-z|A-Z|_|0-9]*)/;
      let youtubeEmbedRegex = /https:\/\/.*?youtube.com\/embed\/(.*)/;
      let youtubeShortRegex = /https:\/\/youtu\.be\/(.*)/;

      let watchMatch = href.match(youtubeWatchRegex);
      let embedMatch = href.match(youtubeEmbedRegex);
      let shortMatch = href.match(youtubeShortRegex);

      if (watchMatch) {
        return "<iframe title=" + title + " width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + watchMatch[1] + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
      } else if (embedMatch) {
        return "<iframe title=" + title + " width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + embedMatch[1] + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
      } else if (shortMatch) {
        return "<iframe title=" + title + " width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + shortMatch[1] + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
      }

      var out = '<a href="' + href + '"';
      if (title) {
        out += ' title="' + title + '"';
      }
      out += '>' + text + '</a>';
      return out;
    };
    this.checkBuild(this.build);
  }

  buildExists() {
    let self = this;
    let loadoutPromise = new Promise((resolve, reject) => {
      self.loadoutsService.getLoadoutFromString(self.build).then((build) => {
        if (build) {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch((err) => {
        reject(err);
      });
    });
    return loadoutPromise;
  }

  getMarkdown() {
    let compiled = this._markdown.compile(this.markdown);

    let regex = /<iframe .*?youtube.com.*?><\/iframe>/g;
    let youtubeWatchRegex = /https:\/\/.*?youtube.com\/watch\?v=([a-z|A-Z|_|0-9]*)/;
    let youtubeEmbedRegex = /https:\/\/.*?youtube.com\/embed\/(.*?)"/;
    let youtubeShortRegex = /https:\/\/youtu.be\/(.*?)\"/;
    let matches = compiled.match(regex);
    compiled = compiled.replace(regex, '[[youtubeLink]]');
    compiled = this.sanitizer.sanitize(1, compiled);
    // Sanitize the iframe matches manually
    if (matches !== null) {
      for(let i = 0; i < matches.length; i++) {
        let watchMatch = matches[i].match(youtubeWatchRegex);
        let embedMatch = matches[i].match(youtubeEmbedRegex);
        let shortMatch = matches[i].match(youtubeShortRegex);

        if (watchMatch) {
          compiled = compiled.replace('[[youtubeLink]]', this.generateEmbed(watchMatch[1]));
        } else if (embedMatch) {
          compiled = compiled.replace('[[youtubeLink]]', this.generateEmbed(embedMatch[1]));
        } else if (shortMatch) {
          compiled = compiled.replace('[[youtubeLink]]', this.generateEmbed(shortMatch[1]));
        }

      }
      return this.sanitizer.bypassSecurityTrustHtml(compiled);
    } else {
      return compiled;
    }
    
  }

  generateEmbed(youtubeId) {
    return "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + youtubeId + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
  }

  checkBuild(buildId) {
    if (buildId.length !== 24) {
      this.buildCheck = checkState.Invalid;
    } else {
      this.buildExists().then((exists) => {
        if (exists) {
          this.buildCheck = checkState.Valid;
        } else {
          this.buildCheck = checkState.Invalid;
        }
      }).catch((err) => {
        console.log(err);
        this.buildCheck = checkState.Invalid;
      });
    }
  }

  hasUnsavedData() {
    return true;
  }

  save() {
    if (this.title.length > 7 && this.buildCheck === 1 && this.markdown.length > 0) {
      let guide = {
        title : this.title,
        builds : [this.build],
        chapters : [{
          title : '',
          markdown : this.markdown
        }],
        processed_html: '',
      };
      if (this.guideId.length !== 0) {
        this.guidesService.editGuide(this.guideId, guide).then((guide: any) => {
          this.router.navigate(['/guides/' + guide.slug]);
        }).catch((error) => {
          this.dialog.open(AlertDialog, {
            data: {message: 'Title is too similar to another title of yours'}
          });
        });
      }
      else {
        this.guidesService.createGuide(guide).then((guide: any) => {
          this.router.navigate(['/guides/' + guide.slug]);
        }).catch((error) => {
          this.dialog.open(AlertDialog, {
            data: {message: 'Title is too similar to another title of yours'}
          });
        });
      }
    }
  }
 }