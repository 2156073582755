import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuidesProvider } from '../../providers/guides/guides';
import { LoadoutsProvider } from '../../providers/loadouts/loadouts';
import { EquipmentProvider } from '../../providers/equipment/equipment';
import { UsersProvider } from '../../providers/users/users';
import { PerksProvider } from '../../providers/perks/perks';
import { DomSanitizer } from '@angular/platform-browser';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss']
})
export class GuidesComponent implements OnInit {


  perkList: any = [];
  guide: any = {};
  loading: boolean = false;
  liked: boolean = false;
  builds: any = [];
  regions: any = ['weapon', 'head', 'torso', 'arms', 'legs', 'lantern'];
  sanitized: boolean = false;
  compiled: string = null;

  constructor(
    private _markdown: MarkdownService,
    public route: ActivatedRoute,
    public guidesService: GuidesProvider,
    public usersService: UsersProvider,
    public loadoutsService: LoadoutsProvider,
    public equipmentService: EquipmentProvider,
    public perksService: PerksProvider,
    public sanitizer: DomSanitizer
  ) {
    this.guide = null;
   }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('slug')) {
      this.loading = true;
      let self = this;
      this.guidesService.getGuide(this.route.snapshot.paramMap.get('slug')).then((data: any) => {
        console.log(data);
        let promises = [];
        data.builds.forEach((build, index) => {
          let buildPromise = new Promise((resolve, reject) => {
            this.loadoutsService.getLoadoutFromString(build).then((buildData: any) => {
              self.builds[index] = buildData;
              resolve();
            }).catch((err) => {
              reject(err);
            });
          });
          promises.push(buildPromise);
        });
        promises.push(new Promise((resolve, reject) => {
          self.perksService.getPerks().then((data) => {
            self.perkList = data;
            resolve();
          });        
        }));

        Promise.all(promises).then(() => {
          this.loading = false;
          console.log(self.builds);
          self.guide = data;
        });
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      });
    }
    this._markdown.renderer.link = function(href, title, text) {
      if (this.options.sanitize) {
        try {
          var prot = decodeURIComponent(unescape(href))
            .replace(/[^\w:]/g, '')
            .toLowerCase();
        } catch (e) {
          return text;
        }
        if (prot.indexOf('javascript:') === 0 || prot.indexOf('vbscript:') === 0 || prot.indexOf('data:') === 0) {
          return text;
        }
      }
      let youtubeWatchRegex = /https:\/\/.*?youtube.com\/watch\?v=([a-z|A-Z|_|0-9]*)/;
      let youtubeEmbedRegex = /https:\/\/.*?youtube.com\/embed\/(.*)/;
      let youtubeShortRegex = /https:\/\/youtu\.be\/(.*)/;

      let watchMatch = href.match(youtubeWatchRegex);
      let embedMatch = href.match(youtubeEmbedRegex);
      let shortMatch = href.match(youtubeShortRegex);

      if (watchMatch) {
        return "<iframe title=" + title + " width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + watchMatch[1] + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
      } else if (embedMatch) {
        return "<iframe title=" + title + " width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + embedMatch[1] + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
      } else if (shortMatch) {
        return "<iframe title=" + title + " width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + shortMatch[1] + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
      }

      var out = '<a href="' + href + '"';
      if (title) {
        out += ' title="' + title + '"';
      }
      out += '>' + text + '</a>';
      return out;
    };
  }

  like() {
    if (this.usersService.loggedIn()) {
      let self = this;
      this.guidesService.like(this.guide._id).then(() => {
        self.liked = true;
        self.guide.totalLikes ++;
      });
    }
  }

  unlike() {
    if (this.usersService.loggedIn()) {
      let self = this;
      this.guidesService.unlike(this.guide._id).then(() => {
        self.liked = false;
        self.guide.totalLikes --;
      });
    }
  }

  isAuthor() {
    if (!this.usersService.getUser() || this.usersService.getUser().id !== this.guide.author._id) {
      return false;
    }
    return true;
  }

  getBuild(buildIndex) {
    if (this.builds.length > buildIndex) {
      return this.builds[buildIndex];
    } else {
      return null;
    }
  }

  getSafeHTML() {
    if (!this.sanitized) {
      let compiled = this._markdown.compile(this.guide.chapters[0].markdown);

      let regex = /<iframe .*?youtube.com.*?><\/iframe>/g;
      let youtubeWatchRegex = /https:\/\/.*?youtube.com\/watch\?v=([a-z|A-Z|_|0-9]*)/;
      let youtubeEmbedRegex = /https:\/\/.*?youtube.com\/embed\/(.*?)"/;
      let youtubeShortRegex = /https:\/\/youtu.be\/(.*?)\"/;
      let matches = compiled.match(regex);
      compiled = compiled.replace(regex, '[[youtubeLink]]');
      compiled = this.sanitizer.sanitize(1, compiled);
      if (matches !== null) {
      // Sanitize the iframe matches manually
        for(let i = 0; i < matches.length; i++) {
          let watchMatch = matches[i].match(youtubeWatchRegex);
          let embedMatch = matches[i].match(youtubeEmbedRegex);
          let shortMatch = matches[i].match(youtubeShortRegex);

          if (watchMatch) {
            compiled = compiled.replace('[[youtubeLink]]', this.generateEmbed(watchMatch[1]));
          } else if (embedMatch) {
            compiled = compiled.replace('[[youtubeLink]]', this.generateEmbed(embedMatch[1]));
          } else if (shortMatch) {
            compiled = compiled.replace('[[youtubeLink]]', this.generateEmbed(shortMatch[1]));
          }

        }
      }
      this.compiled = compiled;
      this.sanitized = true;
    }
    return this.sanitizer.bypassSecurityTrustHtml(this.compiled);
  }

  generateEmbed(youtubeId) {
    return "<iframe width=\"560\" height=\"315\" src=\"https://www.youtube.com/embed/" + youtubeId + "\" frameborder=\"0\" allow=\"autoplay; encrypted-media\" allowfullscreen></iframe>";
  }

  getEquipmentPerks(equipment: any, calculateCells: boolean = true) {
    let perks: any = [];
    if (equipment && equipment.perks) {
      equipment.perks.forEach(perk => {
        if ((equipment.level ? equipment.level : 0) >= perk.levelRequired) {
          let foundPerk = false;
          perks.forEach(perkItem => {
          if(perkItem.perk.perk === perk.perk) {
              foundPerk = true;
              perkItem.level += 1;
            }
          });
          if (!foundPerk) {
            perks.push({'perk': perk, 'level': 1});
          }
        }
      });
    }

    if (calculateCells) {
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += cell.cell.power;
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
            }
          }
        });
      }
    }

    perks.sort(function(a,b) {return a.level < b.level;});

    let perksResult: Array<string> = [];

    perks.forEach(perk => {
      let name: string;

      this.perkList.forEach(populatedPerk => {
        if (populatedPerk._id === perk.perk.perk) {
          name = populatedPerk.name;
        }
      })
      perksResult.push('+' + perk.level + ' ' + name);
    });

    return perksResult;
  }
  
  getCellPerks(equipment: any) {
    let perks: any = [];

    if (equipment && equipment.cellSlots) {
      equipment.cellSlots.forEach(cell => {
        if (cell.cell) {
            perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
        } else {
          perks.push({'perk' : null, 'level': cell.perkType});
        }
      });
    }

    //perks.sort(function(a,b) {return a.level < b.level;});
  
    let perksResult: Array<string> = [];

    perks.forEach(perk => {
      let name: string;

      if (perk.perk === null) {
        perksResult.push('Empty ' + perk.level.charAt(0).toUpperCase() + perk.level.toLowerCase().substr(1) + ' Slot');
        return;
      }

      this.perkList.forEach(populatedPerk => {
        if (populatedPerk._id === perk.perk.perk) {
          name = populatedPerk.name;
        }
      })
      perksResult.push('+' + perk.level + ' ' + name);
    });

    return perksResult;
  }  

}
