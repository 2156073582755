import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EquipmentProvider } from '../../providers/equipment/equipment';


@Component({
  selector: 'app-equipment-modal',
  templateUrl: './equipment-modal.component.html',
  styleUrls: ['./equipment-modal.component.scss']
})
export class EquipmentModalComponent {

  equipmentList: any;
  currentEquipment: any;
  currentRegion: any;
  weaponGroups = [{
      id: 'AXE',
      name: 'Axe'},
    { id: 'CHAIN',
      name: 'Chain Blades'},
    { id: 'HAMMER',
      name: 'Hammer'},
    { id: 'PIKE',
      name: 'War Pike'},
    { id: 'SWORD',
      name: 'Sword'}];

  constructor(
    public dialogRef: MatDialogRef<EquipmentModalComponent>,
    public equipmentService: EquipmentProvider,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.equipmentList = null;
      this.currentRegion = data.region;
      if (data.equipment) {
        this.currentEquipment = data.equipment;
      } else {
        this.currentEquipment = null;
      }
      equipmentService.getEquipmentByRegion(data.region).then((result : Array<any>) => {
        result.forEach((equipment) => {
          equipment.level = equipment.maxLevel;
        });
        this.equipmentList = result;
        this.equipmentList.sort(function(a,b) {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        if (this.currentEquipment) {
          this.equipmentList.forEach((equipment) => {
            if (equipment._id === this.currentEquipment._id) {
              this.currentEquipment = equipment;
            }
          });
        }
    });
  }

  cancelClick(): void {
    console.log('cancel inside');
    this.dialogRef.close();
  }

  okayClick(): void {
    console.log(this.currentEquipment);
    this.dialogRef.close(this.currentEquipment ? this.currentEquipment : null);
  }

  getEquipmentByGroup(group: string) {
    let result = [];
    this.equipmentList.forEach((equipment) => {
      if (equipment.subType === group) {
        result.push(equipment);
      }
    });
    return result;
  }

}
