import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the EquipmentProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class EquipmentProvider {
 host = 'https://my-slayer-server.herokuapp.com';
// host = 'http://localhost:8080';

  data: any;

  constructor(public http: HttpClient) {
    this.data = null;
  }

  getEquipment() {
    if (this.data) {
      return Promise.resolve(this.data);
    }
    return new Promise(resolve => {
      this.http.get(this.host + '/api/equipment')
        .subscribe((data: any) => {
          this.data = data;
          resolve(this.data);
        });
    });
  }

  getEquipmentByRegion(region: string) {
    return new Promise(resolve => {
      this.getEquipment()
        .then(data => {
          let res: Array<any> = [];
          data.forEach(equipment => {
            if (equipment.slot === region) {
              res.push(equipment);
            }
          })
        resolve(res);
      });
    });
  }

  getEquipmentByName(name: string) {
    return new Promise(resolve => {
      this.getEquipment()
      .then(data => {
        data.forEach(equipment => {
          if (equipment.name === name) {
            return resolve(equipment);
          }
        });
        return null;
      });
    });    
  }

  getEquipmentById(id: number) {
    return new Promise(resolve => {
      this.getEquipment()
      .then(data => {
        data.forEach(equipment => {
          if (equipment.id === id) {
            return resolve(equipment);
          }
        });
        return null;
      });
    });    
  }

  getEquipmentIcon(equipment: any) {
    if (equipment === null) {
      return null;
    }
    // 1. Check for exoticName
    // 2. Check for behemoth (if null, recruit)
    // 3. Get behemoth imagePath
    let type = 'armour';
    let subType = 'head';
    let name = 'shrowd';

    if (equipment.subType) {
      type = 'weapons';
      subType = equipment.subType.toLowerCase();
    } else {
      subType = equipment.slot.toLowerCase();
    }

    if (equipment.exoticName) {
      type = 'exotics';
      subType = null;
      name = equipment.exoticName + '.png';
    } else if (equipment.slot === 'LANTERN') {
      type = 'lanterns';
      subType = null;
      name = 'lantern.png';
    } else if (equipment.behemoth) {
      name = equipment.behemoth.imagePath;
    }

    if (subType) {
      return '/assets/imgs/dauntless-images/' + type + '/' + subType + '/' + name;
    } else {
      return '/assets/imgs/dauntless-images/' + type + '/' + name;
    }
  }
}
