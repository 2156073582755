import { Component, OnInit } from '@angular/core';
import { EquipmentProvider } from '../../providers/equipment/equipment';
import { PerksProvider } from '../../providers/perks/perks';
import { LoadoutsProvider } from '../../providers/loadouts/loadouts';

enum Region {
  Weapon = 'WEAPON',
  Head = 'HEAD',
  Torso = 'TORSO',
  Arms = 'ARMS',
  Legs = 'LEGS',
  Lantern = 'LANTERN',
  Stats = 'STATS'
}

@Component({
  selector: 'app-builds-search',
  templateUrl: './builds-search.component.html',
  styleUrls: ['./builds-search.component.scss']
})
export class BuildsSearchComponent implements OnInit {

  loading: boolean = false;
  loadouts = [];
  equipmentList = [];
  perksList = [];
  selectedWeapon = null;

  currentPower = "6";
  currentPerk = null;
  currentItem = null;
  currentUser = null;

  cellFilters = [];
  itemFilters = [];
  userFilters = [];

  loadoutResultPage = 0;

  currentResultPage = 1;
  totalResultsPages = 0;
  totalResults = 0;

  constructor(
    public equipmentService: EquipmentProvider,
    public perksService: PerksProvider,
    public loadoutsService: LoadoutsProvider
  ) { }

  ngOnInit() {
    this.perksService.getPerks().then((perks) => {
      this.perksList = perks;
      this.perksList.sort(function(a,b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    });
    this.equipmentService.getEquipment().then((equipmentList) => {
      this.equipmentList = equipmentList;
      this.equipmentList.sort(function(a,b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });
    });
    this.updateResult();
  }

  getPerks() {
    return this.perksList;
  }

  getItemsByRegion(region: Region) {
    let resArr = [];
    this.equipmentList.forEach((equipment) => {
      if (equipment.slot === region) {
        if (region === Region.Weapon && this.selectedWeapon) {
          if (equipment.subType === this.selectedWeapon) {
            resArr.push(equipment);
          }
        } else {
          resArr.push(equipment);
        }
      }
    })
    return resArr;
  }

  getRegions() {
    return [
      Region.Weapon,
      Region.Head,
      Region.Torso,
      Region.Arms,
      Region.Legs,
      Region.Lantern
    ];
  }

  addItemFilter() {
    if (!this.currentItem) {
      return;
    }
    let removeItem = -1;
    this.itemFilters.forEach((item, idx) => {
      if (item.slot === this.currentItem.slot) {
        removeItem = idx;
      }
    });
    if (removeItem !== -1) {
      this.itemFilters.splice(removeItem, 1);
    }
    this.itemFilters.push(this.currentItem);
    this.currentItem = null;
    this.currentResultPage = 1;
    this.updateResult();
  }

  removeItemFilter(item) {
    let itemIndex = -1;
    this.itemFilters.forEach((arrItem, idx) => {
      if (arrItem === item) {
        itemIndex = idx;
      }
    });
    if (itemIndex !== -1) {
      this.itemFilters.splice(itemIndex, 1);
    }
    this.currentResultPage = 1;
    this.updateResult();
  }

  addUserFilter() {
    if (!this.currentUser || this.currentUser.length < 1) {
      return;
    }
    if (this.userFilters.indexOf(this.currentUser) !== -1) {
      return;
    }
    
    this.userFilters.push(this.currentUser);
    this.currentUser = null;
    this.currentResultPage = 1;
    this.updateResult();
  }

  removeUserFilter(user) {
    let userIndex = -1;
    this.userFilters.forEach((arrItem, idx) => {
      if (arrItem === user) {
        userIndex = idx;
      }
    });
    if (userIndex !== -1) {
      this.userFilters.splice(userIndex, 1);
    }
    this.currentResultPage = 1;
    this.updateResult();
  }

  addCellFilter() {
    if (!this.currentPower || !this.currentPerk) {
      return;
    }
    let removeCell = -1;
    this.cellFilters.forEach((cell, idx) => {
      if (cell.perk === this.currentPerk) {
        removeCell = idx;
      }
    });
    if (removeCell !== -1) {
      this.cellFilters.splice(removeCell, 1);
    }

    this.cellFilters.push({power : this.currentPower, perk : this.currentPerk});
    this.currentPower = "6";
    this.currentPerk = null;
    this.currentResultPage = 1;
    this.updateResult();
  }

  removeCellFilter(cell) {
    let cellIndex = -1;
    this.cellFilters.forEach((arrItem, idx) => {
      if (arrItem === cell) {
        cellIndex = idx;
      }
    });
    if (cellIndex !== -1) {
      this.cellFilters.splice(cellIndex, 1);
    }
    this.currentResultPage = 1;
    this.updateResult();
  }

  nextPage() {
    if (this.currentResultPage !== this.totalResultsPages) {
      this.currentResultPage++;
      this.updateResult();
    }
  }

  previousPage() {
    if (this.currentResultPage > 1) {
      this.currentResultPage--;
      this.updateResult();
    }
  }

  updateResult() {
    let self = this;
    this.loading = true;
    this.loadoutsService.searchLoadouts({
      weaponType : this.selectedWeapon,
      cellFilters : this.cellFilters,
      itemFilters : this.itemFilters,
      userFilters : this.userFilters,
      page : this.currentResultPage
    }).then((results : any) => {
      self.loadouts = results.builds;
      self.totalResultsPages = results.totalPages;
      self.totalResults = results.totalBuilds;
      self.loading = false;
    });
  }

  selectWeapon(weaponType: string) {
    this.selectedWeapon = weaponType;
    if (!!weaponType) {
      let removeItem = -1;
      this.itemFilters.forEach((item, idx) => {
        if (item.slot === 'WEAPON' && item.subType !== weaponType) {
          removeItem = idx;
        }
      });

      if (removeItem !== -1) {
        this.itemFilters.splice(removeItem, 1);
      }
    }
    this.currentResultPage = 1;
    this.updateResult();
  }

  getLoadoutPerks(loadout) {
    let perks: any = [];
    if (loadout.lantern) {
      let equipment = loadout.lantern;
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += cell.cell.power;
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
            }
          }
        });
      }
    }

    if (loadout.head) {
      let equipment = loadout.head;
      if (loadout.head.perks) {
        loadout.head.perks.forEach(perk => {
          if (loadout.head.level ? loadout.head.level : 0 >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += cell.cell.power;
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
            }
          }
        });
      }
    }
    
    if (loadout.torso) {
      let equipment =loadout.torso;
      if (loadout.torso.perks) {
        loadout.torso.perks.forEach(perk => {
          if (loadout.torso.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += cell.cell.power;
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
            }
          }
        });
      }
    }

    if (loadout.arms) {
      let equipment = loadout.arms;
      if (loadout.arms.perks) {
        loadout.arms.perks.forEach(perk => {
          if (loadout.arms.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += cell.cell.power;
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
            }
          }
        });
      }
    }

    if (loadout.legs) {
      let equipment = loadout.legs;
      if (loadout.legs.perks) {
        loadout.legs.perks.forEach(perk => {
          if (loadout.legs.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += cell.cell.power;
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
            }
          }
        });
      }
    }

    if (loadout.weapon) {
      let equipment = loadout.weapon;
      if (loadout.weapon.perks) {
        loadout.weapon.perks.forEach(perk => {
          if (loadout.weapon.level >= perk.levelRequired) {
            let foundPerk = false;
            perks.forEach(perkItem => {
              if(perkItem.perk.perk === perk.perk) {
                foundPerk = true;
                perkItem.level += 1;
              }
            });
            if (!foundPerk) {
              perks.push({'perk': perk, 'level': 1});
            }
          }
        });
      }
      if (equipment && equipment.cellSlots) {
        equipment.cellSlots.forEach(cell => {
          if (cell.cell) {
            let foundPerk = false;
            perks.forEach(perkItem => {
            if(perkItem.perk.perk === cell.cell.perk._id) {
                foundPerk = true;
                perkItem.level += cell.cell.power;
              }
            });          
            if (!foundPerk) {
              perks.push({'perk': { 'perk': cell.cell.perk._id }, 'level': cell.cell.power});
            }
          }
        });
      }
    }  

    perks.sort(function(a,b) {return a.level < b.level;});

    let perksResult: any = [];

    perks.forEach(perk => {
      let name: string;
      let description: string;

      this.perksList.forEach(populatedPerk => {
        if (populatedPerk._id === perk.perk.perk) {
          name = populatedPerk.name;
          description = populatedPerk.effects[perk.level - 1];
        }
      });
      perksResult.push({
        'display' : '+' + perk.level + ' ' + name,
        'description' : description
      });
    })

    return perksResult.slice(0,4);
  } 
  
}
