import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatCheckboxModule, MatIconModule, MatToolbarModule, MatMenuModule, MatButtonModule, MatTooltipModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { EquipmentProvider } from '../providers/equipment/equipment';
import { CellsProvider } from '../providers/cells/cells';
import { PerksProvider } from '../providers/perks/perks';
import { UsersProvider } from '../providers/users/users';
import { LoadoutsProvider } from '../providers/loadouts/loadouts';
import { GuidesProvider } from '../providers/guides/guides';
import { BuildsComponent } from './builds/builds.component';
import { AppRoutingModule } from './app-routing.module';
import { GuidesComponent } from './guides/guides.component';
import { SpeedrunsComponent } from './speedruns/speedruns.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EquipmentModalComponent } from './equipment-modal/equipment-modal.component';
import { CellModalComponent } from './cell-modal/cell-modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { BuildsSearchComponent } from './builds-search/builds-search.component';
import { SaveBuildModalComponent } from './save-build-modal/save-build-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { GuideEditComponent, AlertDialog } from './guide-edit/guide-edit.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import {CanDeactivateGuard} from './can-deactivate/can-deactivate.guard';

@NgModule({
  declarations: [
    AppComponent,
    BuildsComponent,
    GuidesComponent,
    SpeedrunsComponent,
    DashboardComponent,
    EquipmentModalComponent,
    CellModalComponent,
    LoginModalComponent,
    BuildsSearchComponent,
    SaveBuildModalComponent,
    ProfileComponent,
    AlertDialog,
    GuideEditComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    FontAwesomeModule,
    FlexLayoutModule,
    AppRoutingModule,
    FormsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: true,
          smartLists: true,
          smartypants: false,
        }
      }      
    })
  ],
  exports: [
    EquipmentModalComponent,
    CellModalComponent,
    LoginModalComponent,
    SaveBuildModalComponent
  ],
  providers: [
    EquipmentProvider,
    CellsProvider,
    PerksProvider,
    LoadoutsProvider,
    UsersProvider,
    GuidesProvider,
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    EquipmentModalComponent,
    CellModalComponent,
    LoginModalComponent,
    SaveBuildModalComponent,
    AlertDialog
  ]
})
export class AppModule { }
