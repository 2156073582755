import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UsersProvider } from '../../providers/users/users';

enum State {
  Login = 1,
  Register = 2,
  ForgotPassword = 3,
  Processing = 4,
  RegisterSuccess = 5,
  Verify = 6
}

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {
  State = State;

  email : String;
  password: String;
  username: String;
  error: String = null;
  state: State = State.Login;
  authorizing = true;
  authorized = false;

  constructor(public dialogRef: MatDialogRef<LoginModalComponent>, 
    public usersService: UsersProvider,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data && this.data.authId) {
      this.state = State.Verify;
      this.authorizing = true;
      this.usersService.verify(this.data.authId).then((success) => {
        this.authorizing = false;
        this.authorized = true;
      }).catch((error) => {
        this.authorizing = false;
        this.authorized = false;
        this.error = error;
      });
    }
  }


  loginClick() {
    let self = this;
    this.error = null;
    this.state = State.Processing;
    const d = new Date;
    const startTime = d.getTime();

    this.usersService.login(this.email, this.password).then((user) => {
      const minimumDuration = 500;
      let duration = minimumDuration - (d.getTime() - startTime);
      duration = duration < 0 ? 0 : duration;
      setTimeout(() => {
        console.log(user);
        self.dialogRef.close();  
      }, duration);
    }).catch((error) => {
      const minimumDuration = 500;
      let duration = minimumDuration - (d.getTime() - startTime);
      duration = duration < 0 ? 0 : duration;
      setTimeout(() => {
        self.state = State.Login;
        self.error = "Invalid Email / Password";
        console.log(error);
      }, duration);
    });
  }

  cancelClick() {
    this.dialogRef.close();
  }

  forgotClick() {
    this.state = State.ForgotPassword;
  }

  registerClick() {
    this.state = State.Register;
  }

  registerUserClick() {
    let self = this;
    this.error = null;
    this.state = State.Processing;
    const d = new Date;
    const startTime = d.getTime();

    this.usersService.register(this.email, this.username, this.password).then((user) => {
      const minimumDuration = 500;
      let duration = minimumDuration - (d.getTime() - startTime);
      duration = duration < 0 ? 0 : duration;
      setTimeout(() => {
        console.log('Change to RegisterSuccess');
        self.state = State.RegisterSuccess;
      }, duration);
    }).catch((error) => {
      const minimumDuration = 500;
      let duration = minimumDuration - (d.getTime() - startTime);
      duration = duration < 0 ? 0 : duration;
      setTimeout(() => {
        self.state = State.Register;
        self.error = error.error;
        console.log(error);
      }, duration);
    });
  }
  
  backClick() {
    this.state = State.Login;
  }
}
