import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadoutsProvider } from '../../providers/loadouts/loadouts';
import { MatDialog } from '@angular/material';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  topLoadouts : Array<any>;

  constructor(public dialog: MatDialog, public LoadoutService: LoadoutsProvider, public activatedRoute: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.loadout) {
        this.router.navigate(['/builds/' + params.loadout]);
      }

      if (params.authId) {
        this.verify(params.authId);
      }
    });
    this.activatedRoute.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      if (element) { 
        element.scrollIntoView(true);
      }
    });
    this.LoadoutService.getTopLoadouts().then((data: Array<any>) => {
      console.log(data);
      this.topLoadouts = data;
    });
  }

  verify(authId: string) {
    let self = this;
    const dialogRef = this.dialog.open(LoginModalComponent, {
      data: { authId: authId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
      } else {
      }
    });
  }

}
