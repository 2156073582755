import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the LoadoutsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class LoadoutsProvider {

  host = 'https://my-slayer-server.herokuapp.com';
//  host = 'http://localhost:8080';


  constructor(public http: HttpClient) {

  }

  unlikeBuild(buildId: string, userToken: string) {     // /api/builds/unlike/:buildId
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + userToken);

      this.http.get(this.host + '/api/builds/unlike/' + buildId, { headers : headers })
      .subscribe((data: any) => {
        resolve();
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }

  likeBuild(buildId: string, userToken: string) {    // /api/builds/like/:buildId
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + userToken);

      console.log(headers);

      this.http.get(this.host + '/api/builds/like/' + buildId, { headers : headers })
      .subscribe((data: any) => {
        resolve();
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }

  getLoadoutFromString(loadoutString: string) {
    return new Promise((resolve, reject) => {
      console.log('requesting');
      this.http.get(this.host + '/api/builds/' + loadoutString)
      .subscribe((data: any) => {
        console.log('pass on id');
        resolve(data.build);
      }, (error) => {
      // Didn't find by id, try by build string
      console.log('failed on id');
        this.http.get(this.host + '/api/builds/parse/' + loadoutString)
        .subscribe((data: any)  => {
          console.log('pass on parse');
          resolve(data.build);
        }, (error) => {
          console.log('fail on parse');
          reject(error);
        });
      });
    });
  }

  getTopLoadouts() {
      return new Promise(resolve => {
        this.http.get(this.host + '/api/builds')
          .subscribe((data: any)  => {
            let topThree = [];
            if (data.docs.length > 3) {
              for(let i = 0; i < 3; i++) {
                topThree.push(data.docs[i]);
              }
              resolve(topThree);
            } else {
              resolve(data.docs);
            }
          });
      });
  }

  searchLoadouts(options) {
    let weaponType = 'ALL';
    let cellFilters = [];
    let userFilters = [];
    let itemFilters = [];
    let page = 1;
    if (options) {
      cellFilters = options.cellFilters ? options.cellFilters : [];
      userFilters = options.userFilters ? options.userFilters : [];
      itemFilters = options.itemFilters ? options.itemFilters : [];
      weaponType = options.weaponType ? options.weaponType : 'ALL';
      page = options.page ? options.page : 1;
    }

    return new Promise(resolve => {
      this.http.post(this.host + '/api/builds/search/page/' + page, {
        weaponType : weaponType,
        cellFilters : cellFilters,
        userFilters : userFilters,
        itemFilters : itemFilters
      }
    )
        .subscribe((data: any)  => {
          resolve(data);
        });
    });
  }

  getEmptyLoadout() {
    return {
      weapon: null,
      head: null,
      torso: null,
      arms: null,
      legs: null,
      lantern: null
    }
  }

}
