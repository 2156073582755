import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuildsComponent } from './builds/builds.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GuidesComponent } from './guides/guides.component';
import { GuideEditComponent } from './guide-edit/guide-edit.component';
import { SpeedrunsComponent } from './speedruns/speedruns.component';
import { BuildsSearchComponent } from './builds-search/builds-search.component';
import { ProfileComponent } from './profile/profile.component';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'builds', component: BuildsSearchComponent },
  { path: 'builds/new', component: BuildsComponent },
  { path: 'builds/:id', component: BuildsComponent },
  { path: 'guides', component: GuidesComponent },
  { path: 'speedruns', component: SpeedrunsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'profile/:username', component: ProfileComponent },
  { path: 'guides/create', component: GuideEditComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'guides/edit/:slug', component: GuideEditComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'guides/:slug', component: GuidesComponent }
]

@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes) ]
})
export class AppRoutingModule {
}