import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PerksProvider } from '../../providers/perks/perks';

@Component({
  selector: 'app-cell-modal',
  templateUrl: './cell-modal.component.html',
  styleUrls: ['./cell-modal.component.scss']
})
export class CellModalComponent {

  perkList: any;
  currentPerk: any = this.data.currentCell ? this.data.currentCell.perk : null;
  currentPower: any = this.data.currentCell ? this.data.currentCell.power.toString() : "3";

  constructor(
    public dialogRef: MatDialogRef<CellModalComponent>,
    public perksService: PerksProvider,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.perksService.getPerksByType(this.data.perkType).then(result => {
      this.perkList = result;
      this.perkList.forEach((perk) => {
        if (!this.currentPerk) return;
        if (perk._id === this.currentPerk._id) {
          this.currentPerk = perk;
        }
      })
    });
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  okayClick(): void {
    if (this.currentPerk) {
      this.dialogRef.close({perk : this.currentPerk, power: this.currentPower});
    } else {
      this.dialogRef.close(null);
    }
  }
}
