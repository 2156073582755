import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { UsersProvider } from '../providers/users/users';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public dialog: MatDialog, public usersService: UsersProvider) {}

  login() {
    let self = this;
    const dialogRef = this.dialog.open(LoginModalComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        console.log('canceled');
      } else {
      }
    });
  }

  logout() {
    this.usersService.logout();
  }
}
